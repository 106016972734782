import * as React from "react"
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Helmet from "react-helmet";
import ButtonLink from "../components/ButtonLink";
import { StaticImage } from "gatsby-plugin-image"
import TitleSection from "../components/TitleSection";
import Footer from "../components/Footer";

// markup
const Careweb = () => {
    return (
        <>
            <Helmet>
                <title>Careweb uitbreidingen | Meneer Jansen ICT</title>
                <meta name="description" content="Extra diensten beschikbaar voor Careweb, het EPD voor de GGZ en het sociaal domein"/>
                <meta property="og:title" content="Careweb diensten | Meneer Jansen ICT"/>
                <meta property="og:url" content="https://meneerjansen-ict.nl/careweb/"/>
                <meta property="og:description" content="Welke uitbreidingen en diensten zijn er mogelijk met Careweb."/>
                <meta property="og:image" content="https://meneerjansen-ict.nl/images/logo.png"/>
                <body className="page-careweb" />
            </Helmet>

            <Navbar/>

            <main>
                <div className="hero-wrapper">
                    <Hero title="Wat zou het mooi zijn als we met Careweb..." width="50"/>
                    <StaticImage src="../images/pexels-deva-darshan-1173777.jpg" alt="Careweb header" className="hero-image" />
                </div>

                <TitleSection title="Uitbreiden Careweb" subtitle="Wat is er mogelijk?"/>

                <article className="bg-grey">
                    <div className="content">
                        <div className="cols cols-image-first">
                            <div className="col-md-66">
                                <h2>Afspraak reminders</h2>
                                <section className="text-based">
                                    <p>Verlaag het aantal no-shows met afspraak reminders!</p>
                                </section>
                                <section className="ButtonContainer">
                                    <ButtonLink label="Meer over afspraak reminders" to="/careweb/afspraak-reminders"/>
                                </section>
                            </div>

                            <div className="col-md-33 align-vertical-center">
                                <StaticImage src="../images/pexels-mary-taylor-6009145.jpg" alt="Foto van een man die een sms ontvangt"/>
                            </div>

                        </div>
                    </div>
                </article>

                <article className="bg-grey">
                    <div className="content">
                        <div className="cols cols-image-first">

                            <div className="col-md-66">
                                <h2>Website koppeling</h2>
                                <section>
                                    <p>Bespaar tijd door aanmeldingen vanaf je website direct in Careweb te schieten.</p>
                                </section>
                                <section className="ButtonContainer">
                                    <ButtonLink label="Meer over website koppeling" to="/careweb/website-koppeling-aanmelding"/>
                                </section>
                            </div>

                            <div className="col-md-33 align-vertical-center">
                                <StaticImage src="../images/pexels-andrea-piacquadio-3807747.jpg" alt="Afbeelding van vrouw met laptop"/>
                            </div>

                        </div>
                    </div>
                </article>


                <article className="bg-grey">
                    <div className="content">
                        <div className="cols cols-image-first">

                            <div className="col-md-66">
                                <h2>Dossier compleet</h2>
                                <section>
                                    <p>In één oogopslag zien of het dossier in Careweb volledig is.</p>
                                </section>
                                <section className="ButtonContainer">
                                    <ButtonLink label="Meer over dossier compleet" to="/careweb/dossier-compleet"/>
                                </section>
                            </div>

                            <div className="col-md-33 align-vertical-center">
                                <StaticImage src="../images/pexels-davis-sanchez-1727004.jpg" alt="Voorbeeld van een dossier met volledigheid meldingen"/>
                            </div>

                        </div>
                    </div>
                </article>



                {/*<article className="bg-grey">*/}
                {/*    <div className="content">*/}
                {/*        <div className="cols cols-image-first">*/}

                {/*            <div className="col-md-66">*/}
                {/*                <h2>Verschillend maatwerk</h2>*/}
                {/*                <section>*/}
                {/*                    <p>Bijvoorbeeld:</p>*/}
                {/*                    <ul className="check">*/}
                {/*                    <li>Eigen logo en huisstijl op brieven of facturen</li>*/}
                {/*                    <li>Dossier-stoplicht: direct zichtbaar of een dossier compleet is</li>*/}
                {/*                    <li>Rapportages</li>*/}
                {/*                    <li>Brieven</li>*/}
                {/*                    <li>En nog veel meer..</li>*/}
                {/*                    </ul>*/}
                {/*                    <p>*/}
                {/*                        Neem contact op als je wilt weten wat ik voor jou/jullie kan betekenen.*/}
                {/*                    </p>*/}
                {/*                </section>*/}
                {/*                <section className="ButtonContainer">*/}
                {/*                    <ButtonLink label="Neem contact op" to="/contact"/>*/}
                {/*                </section>*/}
                {/*            </div>*/}

                {/*            <div className="col-md-33 align-vertical-center">*/}
                {/*                <StaticImage src="../images/pexels-andrea-piacquadio-3760067.jpg" alt="Foto op kantoor"/>*/}
                {/*            </div>*/}

                {/*        </div>*/}
                {/*    </div>*/}
                {/*</article>*/}


                <article className="bg-grey">
                    <div className="content">

                        <div className="cols  cols-image-first">
                            <div className="col-md-66">
                                <h2>Afspraak inplannen via uw website</h2>
                                <h3>Zoek en boek</h3>
                                <section>
                                    <p>Laat (toekomstige) cliënten zelf een afspraak inplannen op jullie website.</p>
                                    {/*<p>De afspraak staat direct in Careweb. U geeft uiteraard zelf aan wanneer u beschikbaar bent.</p>*/}
                                    {/*<p>*/}
                                    {/*    Neem contact op als je meer wilt weten over afspraak inplannen.*/}
                                    {/*</p>*/}
                                </section>

                                <section className="ButtonContainer">
                                    <ButtonLink label="Neem contact op" to="/contact"/>
                                </section>
                            </div>

                            <div className="col-md-33 align-vertical-center">
                                <StaticImage src="../images/computer-hand.jpg" alt="Vrouw die computer gebruikt"/>
                            </div>

                        </div>
                    </div>
                </article>

                <article className="bg-grey">
                    <div className="content">

                        <div className="cols  cols-image-first">
                            <div className="col-md-66">
                                <h2>Data importeren uit vorig systeem</h2>
                                <h3>Alles over zonder handmatig invoeren</h3>
                                <section>
                                    <p>Gaat u over naar Careweb? Laat uw huidige data door ons overzetten, zo verliest u niets.</p>
                                </section>

                                <section className="ButtonContainer">
                                    <ButtonLink label="Meer over data importeren" to="/careweb/data-importeren"/>
                                </section>
                            </div>

                            <div className="col-md-33 align-vertical-center">
                                <StaticImage src="../images/data-importeren.jpg" alt="Plaatje van data nullen en eenen"/>
                            </div>

                        </div>
                    </div>
                </article>



            </main>

            <Footer />
        </>

    )
}

export default Careweb
